import { LineOfBusinessName } from '../linesOfBusiness';
import { Answers } from '../models';

export class ProCompInputDataValidationError extends Error {
  readonly tag = 'ProCompInputDataValidationError';
}

export type GetPricingAnswersOutput = {
  value: number;
  nodeId: string;
  insuredIndex: number;
}[];

export type FetchPricingAnswersRequest = {
  answers: Answers;
  lineOfBusiness: LineOfBusinessName;
  applicationId: string;
  includeADO?: boolean;
  includeESA?: boolean;
};
